<template>
	<div>
		<div class="mb-10 bg-white filter shadow-default">
			<div class="flex flex-col items-center h-full m-0 p-2 bg-white filter shadow-default z-30">
				<img class="w-44" :src="logo" />

				<div class="w-3/4 flex flex-col items-center">
					<p class="flex text-center uppercase">Портал независимой оценки качества условий оказания услуг медицинскими организациями</p>
				</div>
			</div>
		</div>

		<div class="flex flex-col relative">
			<div
				class="rounded-tr-lg bg-white filter shadow-default"
				:class="{
					'h-300': listVersions.length === 0
				}"
			>
				<table class="table-fixed w-full">
					<thead>
						<tr>
							<th class="w-32 text-center px-6 py-4 border-l border-b border-r border-gray-200 text-left text-md leading-4 font-semibold text-gray-500 tracking-wider">П/П</th>
							<th
								class="relative px-6 py-4 border-l border-b border-r border-gray-200 text-left text-md leading-4 font-semibold text-gray-500 tracking-wider"
								v-for="(item, ind) in fields"
								:key="'J' + ind"
								:class="{ 'w-52': ind === 0 || ind === 1 }"
							>
								<span class="cursor-pointer flex items-center" @click="switchFilterVisibility(ind)">
									{{ item.Name }}

									<Icons v-if="params.filters.find((f) => f.fieldName === item.fieldName)" class="ml-2" icon-name="filter" />
								</span>
								<FilterComponents
									v-on:change="switchFilterVisibility(null)"
									position="left"
									:filterOpen="filterOpen"
									:id-filter="ind"
									:param="params"
									@query="getData"
									:fields="fields"
									:defaultSortField="defaultSort.field"
									:defaultSortOrder="defaultSort.order"
									:item="item"
								/>
							</th>
						</tr>
					</thead>
					<tbody v-for="(item, indx) in listVersions" :key="'A' + indx">
						<tr>
							<td class="px-6 text-center py-4 font-normal text-md whitespace-no-wrap border-l border-b border-r border-gray-200 leading-5 text-gray-500">{{ indx + 1 }}</td>
							<td class="px-6 py-4 font-normal text-md whitespace-no-wrap border-l border-b border-r border-gray-200 leading-5 text-gray-500">{{ item.releaseDate }}</td>
							<td class="px-6 py-4 font-normal text-md whitespace-no-wrap border-l border-b border-r border-gray-200 leading-5 text-gray-500">{{ item.versionCode }}</td>
							<td class="px-6 py-4 font-normal text-md whitespace-no-wrap border-l border-b border-r border-gray-200 leading-5 text-gray-500">{{ item.versionName }}</td>
						</tr>
					</tbody>
				</table>

				<!-- Прелоудер -->
				<div v-if="loading" class="absolute w-full h-full preloader rounded-lg">
					<loadingComponent class="absolute top-1/2 left-1/2" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import loadingComponent from '@/components/Loading.vue';
import FilterComponents from '@/components/Filter.vue';
import Icons from '@/components/Icons.vue';
import logo from '../../assets/img/logo.png';

export default {
	components: {
		loadingComponent,
		FilterComponents,
		Icons
	},
	data: () => ({
		defaultSort: {
			field: 'versionCode',
			order: 1
		},
		filterOpen: null,
		fields: [
			{
				Name: 'Дата релиза',
				fieldName: 'releaseDate',
				filterType: 3,
				filterValueType: 1,
				value1: '',
				value2: null,
				sortBy: null,
				label1: 'По возрастанию',
				label2: 'По убыванию'
			},
			{
				Name: 'Номер версии',
				fieldName: 'versionCode',
				filterType: 2,
				filterValueType: 1,
				value1: '',
				value2: null,
				sortBy: null,
				label1: 'По возрастанию',
				label2: 'По убыванию'
			},
			{
				Name: 'Описание',
				fieldName: 'versionName',
				filterType: 2,
				filterValueType: 1,
				value1: '',
				value2: null,
				sortBy: null,
				label1: 'По возрастанию',
				label2: 'По убыванию'
			}
		],
		actualVersion: null,
		logo: logo,
		data: [],
		loading: true,
		listVersions: [],
		params: {
			filters: [],
			sortOption: {
				fieldName: 'versionCode',
				sortType: 1
			},
			pageSize: 999,
			currentPage: 0,
			useANDoperator: true,
			columns: []
		}
	}),
	methods: {
		switchFilterVisibility(key) {
			this.filterOpen = this.filterOpen === key ? null : key;
		},
		getData(params) {
			this.loading = true;

			this.$http.post('ProjectVersion/GetProjectVersions', params).then((res) => {
				for (let i = 0; i < res.data.data.length; i += 1) {
					if (res.data.data[i].releaseDate) res.data.data[i].releaseDate = new Date(res.data.data[i].releaseDate).toLocaleDateString();
				}

				this.listVersions = res.data.data;
				this.listVersionsFreeze = res.data.data;

				this.loading = false;
			});
		}
	},
	created() {
		this.getData(this.params);
	}
};
</script>

<style scoped>
.h-300 {
	height: 300px;
}
.mh-300 {
	min-height: 300px;
}
</style>
